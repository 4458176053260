<template>
  <nav
    v-if="communityContent"
    ref="communityNav"
    :style="{
      backgroundColor: communityContent.backgroundDark.color,
      color: communityContent.contentDark.color,
    }"
    :class="{
      sticky: !isLandingPageContentType,
      'fixed z-20': isLandingPageContentType,
      'ease-out-expo transform transition-transform duration-700':
        isLandingPageContentType,
      '-translate-y-full':
        !hasScrolledPastThreshold && isLandingPageContentType,
      'translate-y-0': hasScrolledPastThreshold && isLandingPageContentType,
    }"
    class="top-0 z-30 h-[--communityNavigationHeightMobile] w-full lg:h-[--communityNavigationHeightDesktop]"
    aria-label="Community navigation bar"
  >
    <div class="container flex h-full items-center gap-5">
      <figure
        v-if="communityContent.logoDark?.filename"
        class="flex h-full flex-none flex-grow items-center"
      >
        <NuxtLink
          v-if="!isLandingPageContentType"
          :to="formatLink(communitySlug)"
          class="h-full"
          aria-label="Go to community home"
        >
          <NuxtImg
            :src="communityContent.logoDark.filename"
            :alt="communityContent.logoDark.alt"
            class="pointer-events-none h-full"
          />
        </NuxtLink>
        <NuxtImg
          v-else
          :src="communityContent.logoDark.filename"
          :alt="communityContent.logoDark.alt"
          class="pointer-events-none h-full"
        />
      </figure>
      <template v-if="!isLandingPageContentType">
        <ul
          v-for="menu in communityContent.communityNavigation"
          :key="menu._uid"
          class="hidden h-full items-center justify-self-end lg:flex"
          role="menubar"
        >
          <li
            v-for="(menuItem, menuItemIndex) in menu.menuItems"
            :key="menuItem._uid"
            class="group h-full"
            role="none"
          >
            <StoryblokComponent
              :blok="menuItem"
              :custom-color-theme="
                getCommunityThemeMenuLinkCSSVars(communityContent)
              "
              :icon-classes="
                menuItem.childMenu?.length
                  ? 'c-community-nav-menu-icon ease-out-expo transition-transform duration-700 group-hover:rotate-180'
                  : undefined
              "
              :hide-icon="!menuItem.childMenu?.length"
              :data-has-mega-menu="!!menuItem.childMenu?.length"
              :data-menu-item-index="menuItemIndex"
              :is-desktop-navigation="true"
              icon-size="md"
              class="h-full px-3"
              @mouseenter="onMenuItemMouseEnter(menuItem)"
            />

            <NavigationDesktopMegaMenu
              v-for="childMenu in menuItem.childMenu"
              ref="megaMenuRefs"
              :key="childMenu._uid"
              :menu="childMenu"
              :label="communityName"
              :custom-color-theme="
                getCommunityThemeMegaMenuCSSVars(communityContent)
              "
              :menu-item-index="menuItemIndex"
              offset="communityNavigation"
            />
          </li>
        </ul>
      </template>

      <div
        v-if="communityContent.ctaButtons?.length"
        class="flex items-center gap-3"
      >
        <template v-if="!isLandingPageContentType">
          <UiButton
            v-if="!communityContent.hideVisitUsButton"
            :size="communityContent.ctaButtons?.[0].size"
            :custom-color-theme="
              getCommunityThemeButtonCSSVars(
                'outline',
                'light',
                communityContent,
              )
            "
            theme="light"
            label="Visit Us"
            variant="outline"
            mobile-size="sm"
            data-visit-us-panel-button="true"
            @click="
              ($event: Event) =>
                toggleCommunityVisitUsPanel($event.target as HTMLElement)
            "
          />
        </template>

        <template
          v-if="
            !isLandingPageContentType && !isNewsArticleContentType && showCTAs
          "
        >
          <StoryblokComponent
            v-for="ctaButton in communityContent.ctaButtons"
            :key="ctaButton._uid"
            :blok="ctaButton"
            :custom-color-theme="
              getCommunityThemeButtonCSSVars(
                ctaButton.variant as UiButton.Variant,
                'light',
                communityContent,
              )
            "
            mobile-size="sm"
          />
        </template>

        <template v-if="isLandingPageContentType">
          <StoryblokComponent
            v-for="ctaButton in (
              story?.content as ContentTypeLandingPageStoryblok
            ).ctaButtons"
            :key="ctaButton._uid"
            :blok="ctaButton"
            :custom-color-theme="
              getCommunityThemeButtonCSSVars(
                ctaButton.variant as UiButton.Variant,
                'light',
                communityContent,
              )
            "
            mobile-size="sm"
          />
        </template>
      </div>
    </div>

    <CommunityNavigationVisitUs />
  </nav>
</template>

<script setup lang="ts">
import type NavigationDesktopMegaMenu from '@/components/navigation/NavigationDesktopMegaMenu.vue'
import type { UiButton } from '@/components/ui/types'
import type {
  BlockNavigationMenuItemStoryblok,
  ContentTypeLandingPageStoryblok,
} from '@/types/storyblok'
import {
  getCommunityThemeButtonCSSVars,
  getCommunityThemeMenuLinkCSSVars,
  getCommunityThemeMegaMenuCSSVars,
} from '@/utils/theme-utils'
import useMegaMenu from '@/composables/useMegaMenu'
import { formatLink } from '@/utils/link-utils'
import {
  isContentTypeLandingPageStoryblok,
  isContentTypeNewsArticleStoryblok,
} from '@/utils/sb-content-type-refiners'
import { remToPx } from '@/utils/common-utils'

// we need to wait for the story to be ready before we can use it
await useCurrentStoryStore().isReady

const { toggleCommunityVisitUsPanel, closeCommunityVisitUsPanel } =
  useCommunityStore()
const { communityName, communityContent, communitySlug, story } = storeToRefs(
  useCurrentStoryStore(),
)
const isLandingPageContentType = computed(() =>
  isContentTypeLandingPageStoryblok(story.value),
)
const isNewsArticleContentType = computed(() =>
  isContentTypeNewsArticleStoryblok(story.value),
)
// Exact check for boolean false value, as we don't want to include stories with no "showCTAs" key
const showCTAs = computed(() => story.value?.content.showCTAs !== false)
const communityNav = ref<HTMLElement | null>(null)
const megaMenuRefs = ref<InstanceType<typeof NavigationDesktopMegaMenu>[]>([])
const hasScrolledPastThreshold = ref<boolean>(false)
const { ready, setupEventListeners, removeEventListeners } =
  useMegaMenu(megaMenuRefs)

onMounted(() => {
  ready(communityNav.value)
  setupEventListeners()
  const rootCSSStyles = getComputedStyle(document.body)
  window.addEventListener('scroll', onScroll.bind(null, rootCSSStyles))
})

onBeforeUnmount(() => {
  removeEventListeners()
  const rootCSSStyles = getComputedStyle(document.body)
  window.removeEventListener('scroll', onScroll.bind(null, rootCSSStyles))
})

function onScroll(style: CSSStyleDeclaration): void {
  hasScrolledPastThreshold.value =
    window.scrollY >
    remToPx(style.getPropertyValue('--globalHeaderHeightDesktopCondensed'))
}

function onMenuItemMouseEnter(
  menuItem: BlockNavigationMenuItemStoryblok,
): void {
  if (menuItem.childMenu?.length) {
    closeCommunityVisitUsPanel()
  }
}
</script>

<style lang="postcss">
@media (pointer: coarse) {
  .is-active > .c-community-nav-menu-icon {
    @apply !rotate-180;
  }

  :not(.is-active) > .c-community-nav-menu-icon {
    @apply !rotate-0;
  }
}
</style>
