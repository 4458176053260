import { toggleBodyScroll } from '@/utils/common-utils'

export const useCommunityStore = defineStore('community', () => {
  const visitUsPanelInvoker = ref<HTMLElement | null>(null)
  const isCommunityVisitUsPanelOpen = ref(false)

  function toggleCommunityVisitUsPanel(invoker: HTMLElement | null): void {
    isCommunityVisitUsPanelOpen.value = !isCommunityVisitUsPanelOpen.value
    visitUsPanelInvoker.value = invoker
    toggleBodyScroll(isCommunityVisitUsPanelOpen.value)
  }

  function closeCommunityVisitUsPanel(): void {
    isCommunityVisitUsPanelOpen.value = false
    toggleBodyScroll(false)

    if (visitUsPanelInvoker.value) {
      visitUsPanelInvoker.value.focus()
      visitUsPanelInvoker.value = null
    }
  }

  function reset(): void {
    closeCommunityVisitUsPanel()
    toggleBodyScroll(false)
  }

  return {
    closeCommunityVisitUsPanel,
    isCommunityVisitUsPanelOpen,
    reset,
    toggleCommunityVisitUsPanel,
  }
})
