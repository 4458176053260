<template>
  <Drawer
    :is-open="isCommunityVisitUsPanelOpen"
    :style="{
      '--custom-fill-color': communityContent?.backgroundDark.color,
    }"
    @close="closeCommunityVisitUsPanel"
  >
    <div
      v-show="isCommunityVisitUsPanelOpen"
      ref="panel"
      :aria-hidden="!isCommunityVisitUsPanelOpen"
      :style="{
        '--custom-text-color': communityContent?.contentDark.color,
      }"
      aria-role="dialog"
      aria-labelledby="visit-us-panel-title"
      class="container pb-[--communityTabBarHeight]"
    >
      <CommunityNavigationVisitUsPanel />
    </div>
  </Drawer>

  <Transition name="fade">
    <div
      v-show="isCommunityVisitUsPanelOpen"
      ref="panel"
      :style="{
        '--custom-fill-color': communityContent?.backgroundDark.color,
        '--custom-text-color': communityContent?.contentDark.color,
      }"
      class="absolute left-0 top-[--communityNavigationHeightDesktop] hidden w-full border-t border-white/10 bg-[--custom-fill-color] text-[--custom-text-color] lg:block"
    >
      <div class="container flex flex-col gap-8 pb-16 pt-4">
        <button class="self-end" @click="closeCommunityVisitUsPanel">
          <span class="sr-only">Close panel</span>
          <UiIcon icon="close" size="md" />
        </button>

        <CommunityNavigationVisitUsPanel />
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import screens from '#tailwind-config/theme/screens'
import { onClickOutside } from '@vueuse/core'
import {
  findFirstFocusableElement,
  setupTabTrapping,
  removeTabTrapping,
} from '@/utils/accessibility'
import { stripPx } from '@/utils/common-utils'

const panel = ref<HTMLElement | null>(null)
const communityStore = useCommunityStore()
const { closeCommunityVisitUsPanel } = communityStore
const { isCommunityVisitUsPanelOpen } = storeToRefs(communityStore)
const { communityContent } = storeToRefs(useCurrentStoryStore())

let handleTabEvent: (event: KeyboardEvent) => void

onMounted(() => {
  window.addEventListener('keyup', onKeyup)
})

onBeforeUnmount(() => {
  window.removeEventListener('keyup', onKeyup)
})

watch(isCommunityVisitUsPanelOpen, (isOpen) => {
  nextTick(() => {
    if (isOpen) {
      if (panel.value) {
        const firstFocusableElement = findFirstFocusableElement(panel.value)
        firstFocusableElement?.focus()
        handleTabEvent = setupTabTrapping(panel.value)

        onClickOutside(panel, (event) => {
          const largeScreen = stripPx(screens.lg)

          if (
            window.innerWidth < largeScreen ||
            (event.target as HTMLElement).parentElement?.dataset
              .visitUsPanelButton
          ) {
            return
          }

          closeCommunityVisitUsPanel()
        })
      }
    } else {
      if (panel.value) {
        removeTabTrapping(panel.value, handleTabEvent)
      }
    }
  })
})

function onKeyup(e: KeyboardEvent) {
  if (e.key === 'Escape') {
    closeCommunityVisitUsPanel()
  }
}
</script>
