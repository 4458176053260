<template>
  <nav
    v-if="!isLandingPageContentType"
    class="c-tab-bar fixed bottom-0 left-0 z-40 h-[--communityTabBarHeight] w-full bg-white lg:hidden"
  >
    <div class="container z-10 h-full px-0">
      <ul
        v-for="menu in communityContent?.communityNavigation"
        :key="menu._uid"
        class="flex size-full overflow-auto before:m-auto before:content-[''] after:m-auto after:content-['']"
      >
        <li
          v-for="menuItem in menu.menuItems"
          :key="menuItem._uid"
          class="h-full w-[4.6875rem] flex-none"
        >
          <NuxtLink
            :to="formatLink(menuItem.link?.cached_url)"
            :target="menuItem.link?.target"
            :rel="
              isExternalLink(menuItem.link?.cached_url) ? 'external' : undefined
            "
            :class="{
              'text-gray-900': isCurrentPage(
                menuItem.link?.cached_url,
                story?.full_slug,
              ),
            }"
            tabindex="0"
            role="menuitem"
            class="ease-out-expo text-xxs relative flex h-full cursor-pointer flex-col items-center justify-center text-gray-700 transition-colors duration-700 hover:text-gray-900"
            @click="handleTabButtonClick(menuItem)"
          >
            <div
              v-if="isCurrentPage(menuItem.link?.cached_url, story?.full_slug)"
              :style="{
                '--indicator-fill-color': communityContent?.contentLight.color,
              }"
              class="absolute left-1/2 top-0 h-1 w-[calc(100%-0.75rem)] -translate-x-1/2 transform rounded-xl bg-[--indicator-fill-color]"
            />

            <UiIcon
              v-if="menuItem.icon"
              :icon="menuItem.icon as Icon"
              size="lg"
            />

            {{ menuItem.label }}
          </NuxtLink>
        </li>
      </ul>
    </div>
  </nav>

  <Drawer
    v-if="!isLandingPageContentType"
    :is-open="isDrawerOpen"
    @close="closeDrawer"
  >
    <div
      v-for="navigationMenus in activeDrawerMenu"
      :key="navigationMenus._uid"
      class="flex flex-col gap-16"
    >
      <ul
        v-for="(
          navigationMenu, navigationMenuIndex
        ) in navigationMenus?.menuItems"
        :key="navigationMenu._uid"
        :class="{
          'gap-6': navigationMenuIndex === 0,
          'gap-4': navigationMenuIndex === 1,
        }"
        class="col-span-3 flex flex-col"
      >
        <li v-for="menuItem in navigationMenu.menuItems" :key="menuItem._uid">
          <StoryblokComponent
            :blok="menuItem"
            :class="{
              'text-lg font-bold text-gray-700': navigationMenuIndex === 0,
              'text-sm text-gray-900': navigationMenuIndex === 1,
            }"
            class="flex w-full items-center gap-[2px]"
            @click="onClick"
          />
        </li>
      </ul>
    </div>
  </Drawer>
</template>

<script setup lang="ts">
import type {
  BlockNavigationMenuWrapperStoryblok,
  BlockNavigationMenuItemStoryblok,
} from '@/types/storyblok'
import type { Icon } from '@/components/ui/UiIcon.vue'
import { formatLink, isExternalLink, isCurrentPage } from '@/utils/link-utils'

// we need to wait for the story to be ready before we can use it
await useCurrentStoryStore().isReady

const { story, communityContent } = storeToRefs(useCurrentStoryStore())
const isLandingPageContentType = computed(() =>
  isContentTypeLandingPageStoryblok(story.value),
)
const isDrawerOpen = ref<boolean>(false)
const activeDrawerMenu = ref<BlockNavigationMenuWrapperStoryblok[] | undefined>(
  undefined,
)

function handleTabButtonClick(
  menuItem: BlockNavigationMenuItemStoryblok,
): void {
  if (!menuItem?.childMenu?.length) {
    return
  }

  isDrawerOpen.value = true
  activeDrawerMenu.value = menuItem?.childMenu
}

function closeDrawer() {
  isDrawerOpen.value = false
}

function onClick() {
  isDrawerOpen.value = false
}
</script>

<style scoped lang="postcss">
.c-tab-bar {
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.05);
}
</style>
