<template>
  <div v-if="communityContent" class="flex flex-col gap-3 lg:gap-8">
    <div class="grid grid-cols-12 gap-y-20 lg:gap-4">
      <div class="col-span-full flex flex-col gap-y-10 lg:col-span-6">
        <p
          v-if="communityContent.shortDescription"
          id="visit-us-panel-title"
          class="text-xl font-bold"
        >
          {{ communityContent.shortDescription }}
        </p>

        <div
          v-if="showBrochureButton || showDirectionsButton"
          class="flex flex-col gap-3 lg:flex-row"
        >
          <UiButton
            v-if="showBrochureButton"
            :link="communityContent.brochure?.filename"
            :custom-color-theme="
              getCommunityThemeButtonCSSVars(
                'outline',
                'light',
                communityContent,
              )
            "
            :full-width-on-mobile="true"
            :is-download="true"
            target="_blank"
            theme="light"
            label="Download Brochure"
            variant="outline"
            left-icon="download"
            class="w-full lg:w-auto"
          />

          <UiButton
            v-if="showDirectionsButton && !communityContent.hideVisitUsButton"
            :link="getDirectionsLink(communityContent)"
            :custom-color-theme="
              getCommunityThemeButtonCSSVars(
                'outline',
                'light',
                communityContent,
              )
            "
            :full-width-on-mobile="true"
            target="_blank"
            theme="light"
            label="Get Directions"
            variant="outline"
            left-icon="marker"
          />
        </div>
      </div>

      <div class="col-span-full flex flex-col gap-5 lg:col-span-5">
        <div>
          <h3 class="font-bold">Sales and Information Centre</h3>
          <DisplaySuiteLocation :community-content="communityContent" />
        </div>

        <OpeningHours
          v-if="communityContent.openingHours"
          :data="communityContent.openingHours"
          theme="dark"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getCommunityThemeButtonCSSVars } from '@/utils/theme-utils'
import { getDirectionsLink } from '@/utils/map-utils'

const { communityContent } = storeToRefs(useCurrentStoryStore())
const showBrochureButton = computed(() => {
  return communityContent.value?.brochure?.filename
})
const showDirectionsButton = computed(() => {
  return !!(
    communityContent.value?.mapLocationLatitude &&
    communityContent.value?.mapLocationLongitude
  )
})
</script>
